import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.png";
import "../styles/spinner.css";
import {baseUrl} from '../baseUrl';
import axiosBaseURL from '../baseUrl';

const SpinnerMain = () => {
  // const [spinnerData, setSpinnerData] = useState(null);


  // useEffect(() => {
  //   axiosBaseURL.get("/api/index", {                     
  //   }).then((res) => {
  //     setSpinnerData(res.data);
     
  //   })
  // }, []);
  // const spin = spinnerData.setting;
  const [spinnerData, setSpinnerData] = React.useState({});
  
  
  React.useEffect(() => {
    window.scrollTo(0, 0)
    axiosBaseURL.get(`/api/index`)
      .then(res => {
        setSpinnerData(res.data);
       
      }
      )
      .catch(err => console.log(err))
  }, []);
  const spin = spinnerData.setting;
 
  return (
    <div className='spinner_main'>
      {
                        spin && spin.map(function (item, index) {
                            return (
                                <div className="logo_main"
                                    key={index}>
                                    <img src={
                                            baseUrl + item ?. logo
                                        }
                                        alt="spinner"
                                        className='spinner_logo'/>


                                </div>
                            )

                        })
                    }
     {/* <img src={Logo} alt="spinner" className='spinner_logo' /> */}
     </div>
  )
}

export default SpinnerMain
 