import React from 'react'
import "../styles/notfound.css"
import "../styles/responsive.css"
import error from "../images/error.webp"

const NoDataFound = () => {
  return (
    <div className='not_found'>
      <img src={error} alt="Error" />
       
        </div>
  )


}

export default NoDataFound