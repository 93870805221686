
import React, {useState} from 'react'
import { useParams } from 'react-router-dom';
import axiosBaseURL from '../baseUrl';
import SpinnerMain from '../components/Spinner';
import { FiMail } from "react-icons/fi";
import "../styles/abroad.css"
import '../styles/applyNow.css';
import "../styles/responsive.css"
import 'react-tabs/style/react-tabs.css';
import { toast, ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { baseUrl } from '../baseUrl';


const Enroll = ({ props }) => { 
    const { id } = useParams();
    const [enrollsData, setEnrollsData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    
    React.useEffect(() => {
      window.scrollTo(0, 0)
      console.log(id)
      axiosBaseURL.get(`/api/course/${id}`)
        .then(res => {
            setEnrollsData(res.data);
            console.log(res.data);
          setIsLoading(false);
          
        }
        )
        .catch(err => console.log(err))
    }, [props?.match?.params?.id, id]);
    const enrollData = enrollsData.course;


    const [active, setActive] = useState(true);

    function closeItem() {
      setActive(true);
     
    }
    function openItem() {
      setActive(false);
    
    }


    const [enrollNowData, setEnrollNowData] = React.useState({
      fullname: '',
      email: '',
      address: '',
      name_of_test:'',
      score:'',
      date_of_test:'',
      interested_country:'',
      phone: '',
      message: '',     
  });
  const [error, setError] = React.useState(false);
  const { fullname, email, address, name_of_test, score, date_of_test, interested_country, message, phone } = enrollNowData;

  const handleChange = (e) => {
    setEnrollNowData({
          ...enrollNowData,
          [e.target.name]: e.target.value
      });
  }

  var bodyFormData = new FormData();
  Object.entries(enrollNowData).forEach(([key, value]) => {
      bodyFormData.append(key, value);
  });
  const handleSubmit = (e) => {
      e.preventDefault();
     
      axiosBaseURL.post(`/api/course/${id}`, bodyFormData, { headers: { 'Content-Type': 'application/json' } })
          .then(
              (res) => {
                  toast.success("Applied Successfully")
                  console.log(res)
                  setEnrollNowData({
                    fullname: '',
                    email: '',
                    address: '',
                    name_of_test:'',
                    score:'',
                    date_of_test:'',
                    interested_country:'',
                    phone: '',
                    message: '',
                  })
                  setError(" ")
                
              }
          )
          .catch((res) => {
              setError(res?.response?.data?.errors)
              toast.error("Please Fill All Fields")
          });
        }

  return (
    <>
    {isLoading? <><Helmet>
      <title>Study in |</title>
      </Helmet>
      </> :
      <Helmet>
      <title> Study in | {`${enrollData?.title}`}</title>
    </Helmet>
      }
      
      {isLoading ? <SpinnerMain /> :
        <div>
     
          <div className="abroads_main">
            <div className="abroads_banner">
            <img src={baseUrl + "/images/course/" + enrollData?.image} alt="Course" />
              <div className="banner_above">
              <img src={baseUrl + "/images/course/logo/" + enrollData?.logo} alt="Course" />
                <h5>{enrollData?.title}</h5>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="overview_abroad">
                    <h4>{enrollData?.title} </h4>
                    <p dangerouslySetInnerHTML={{ __html: enrollData?.description }}></p>
                  </div>
                </div>
                <div className="col-md-4 apply_col">
                <div className="apply_online_form">
                            <div className="icon_apply">
                                <div className="icon_main">
                                    <FiMail style={{ color: "black" }} />
                                </div>
                                <h4>Apply online</h4>
                            </div>
                            <form className='apply_online' onSubmit={handleSubmit}>
                                <div className="fields">
                                    <label htmlFor="fullname">Name<span>*</span></label>
                                    <input type="text" name='fullname' required value={fullname} onChange={handleChange} />
                                    {error?.fullname && <p className="error">{error.fullname}</p>}
                                </div>
                                <div className="fields">
                                    <label htmlFor="email">Email<span>*</span></label>
                                    <input type="email" name='email' required  value={email} onChange={handleChange} />
                                    {error?.email && <p className="error">{error.email}</p>}
                                </div>
                                <div className="fields">
                                    <label htmlFor="address">Address</label>
                                    <input type="text" name='address' required value={address} onChange={handleChange} />
                                    {error?.address && <p className="error">{error.address}</p>}
                                </div>



                                <div className="fields">
                                    <label>
                                       Have you appeared any language proficiency test ? </label>
                                      <div>
                                        <input type="radio" name='conformation'  onClick={openItem} /> Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input type="radio" name='conformation'  onClick={closeItem}/> No
                                       </div>
                                   
                                </div>

                                <div className={active ? "close" : "open"}>                                
                                <div className="fields">
                                    <label>
                                        Name of the Test:
                                        <select
                                            name="name_of_test"
                                            value={name_of_test}
                                            onChange={handleChange}
                                            
                                        >
                                            <option value="itels">IELTS</option>
                                            <option value="Toefl">TOEFL</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="fields">
                                    <label htmlFor="ielts">Score<span>*</span></label>
                                    <input type="text" name='score' value={score} onChange={handleChange}/>
                                    {error?.score && <p className="error">{error.score}</p>}
                                </div>
                                <div className="fields">
                                    <label htmlFor="ielts">Date of test<span>*</span></label>
                                    <input type="date"  max={new Date().toISOString().split('T')[0]} name='date_of_test' value={date_of_test} onChange={handleChange} />
                                    
                                </div>
                                </div>



                                <div className="fields">
                                    <label htmlFor="ielts">Interested Country<span>*</span></label>
                                    <input type="text" name='interested_country' required value={interested_country} onChange={handleChange} />
                                    {error?.interested_country && <p className="error">{error.interested_country}</p>}
                                </div>
                                <div className="fields">
                                    <label htmlFor="phone">Phone no<span>*</span></label>
                                    <input type="number" name='phone' required value={phone} onChange={handleChange} />
                                    {error?.phone && <p className="error">{error.phone}</p>}
                                </div>
                                <div className="fields">
                                    <label htmlFor="message">Message (if any)</label>
                                    <textarea name="message" cols="30" rows="2" required value={message} onChange={handleChange}></textarea>
                                </div>
                                <div className="submit_button_abroad">
                                    <button type='submit' className='apply_now_abroad'>Submit</button>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                </div>
              </div>
            </div>
           
          </div>
         
        </div>
}
    </>
  )
}

export default Enroll