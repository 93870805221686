import React from 'react';
import axiosBaseURL from '../baseUrl';
import { baseUrl } from '../baseUrl';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/team.css'; // Keep your custom styles for additional design

const Team = () => {
  const [teamData, setTeamData] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    axiosBaseURL
      .get('/api/teams')
      .then((res) => {
        setTeamData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Meet Our Team</title>
      </Helmet>
      <div className="team_main mt-4 py-5 bg-light">
        <div className="container">
          {/* Section Heading */}
          <div className="row text-center mb-4">
            <div className="col-12">
              <h3 className="text-uppercase fw-bold">Meet Our Team</h3>
              <p className="text-muted">
                Discover the passionate individuals behind our success.
              </p>
              <div
                className="mx-auto"
                style={{
                  width: '80px',
                  height: '4px',
                  backgroundColor: '#007bff',
                }}
              ></div>
            </div>
          </div>
          {/* Team Cards */}
          <div className="row">
            {loading ? (
              <div className="col-12 text-center">
                <p className="text-muted">Loading...</p>
              </div>
            ) : teamData?.teams?.length > 0 ? (
              teamData?.teams?.map((team, index) => (
                <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                  <div className="card shadow-sm border-0">
                    <img
                      src={`${baseUrl}/images/team/${team?.image}`}
                      alt="team"
                      className="card-img-top"
                      style={{ height: '200px', objectFit: 'cover' }}
                    />
                    <div className="card-body text-center">
                      <h5 className="card-title fw-bold">{team?.title}</h5>
                      <p className="card-text text-muted">{team?.role}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">
                <div className="alert alert-warning">
                  <h5>No Teams Found</h5>
                  <p>We are currently updating our team details. Please check back later.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
